"use client"
import React from "react"
import { Image } from "@/components/shared/image/image"
import Link from "next/link"
import ButtonAction from "../shared/button-action/button-action"
import { useIsLogin } from "@/hooks/auth/use-is-login"
import { useIsDesktop } from "@/hooks/use-is-mobile"
import { urlAsset } from "@/helper/utils"

const CardNotFound = () => {
  const { isLogin } = useIsLogin();
  const isDesktop = useIsDesktop();

  return (
    <>
      <Image
        className="absolute inset-0 z-[0]"
        alt="bg"
        src={`${urlAsset()}/profile-edit/bg-${isDesktop ? "desktop" : "mobile"}.png`}
      />
      <div className="flex flex-col justify-center pt-[66px] lg:pt-[90px] px-[25px] max-w-[460px] mx-auto relative z-[1]">
        <div>
          <Image
            className="aspect-[304/177] max-w-[304px] mx-auto"
            src={`${urlAsset()}/404/404-mobile.png`}
            alt="404"
          />
          <div className="text-[20px] lg:text-[24px] leading-[28px] lg:leading-[32px] font-font-family-7 font-bold mt-[20px] capitalize text-center text-text-2">
            Oops! Halaman Tidak Ditemukan
          </div>
          <div className="text-[16px] leading-[24px] mt-[10px] text-center text-text-2 font-font-family-5">
            Maaf, sepertinya halaman yang{" "}
            {process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "kamu" : "lo"} cari
            tidak tersedia atau telah dipindahkan. Silakan periksa kembali URL
            atau kembali ke <i>Homepage</i> untuk melanjutkan.
          </div>
          <Link href={isLogin ? "/br" : "/"}>
            <ButtonAction
              className="mt-[20px] max-w-[355px] mx-auto"
              intent={"primary"}
            >
              Kembali Ke Homepage
            </ButtonAction>
          </Link>
        </div>
      </div>
    </>
  );
};

export default CardNotFound;
